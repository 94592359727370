body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @tailwind preflight;
@tailwind utilities;

@tailwind base;
@tailwind components; */

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity))
}

.border-solid {
  border-style: solid
}

.border-0 {
  border-width: 0px
}

.border {
  border-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-flex;
  display: flex
}

.table {
  display: table
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.font-semibold {
  font-weight: 600
}

.h-10 {
  height: 1.0rem
}

.h-48 {
  height: 4.8rem
}

.h-72 {
  height: 7.2rem
}

.h-full {
  height: 100%
}

.text-16 {
  font-size: 1.6rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.leading-none {
  line-height: 1
}

.m-20 {
  margin: 2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.mb-0 {
  margin-bottom: 0
}

.mb-12 {
  margin-bottom: 1.2rem
}

.min-h-0 {
  min-height: 0
}

.min-h-72 {
  min-height: 7.2rem
}

.min-w-40 {
  min-width: 4rem
}

.p-0 {
  padding: 0
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.resize {
  resize: both
}

* {
  --tw-shadow: 0 0 #0000
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(33, 150, 243, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.underline {
  text-decoration: underline
}

.invisible {
  visibility: hidden
}

.w-256 {
  width: 25.6rem
}

.w-full {
  width: 100%
}

.z-10 {
  z-index: 10
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur {
  --tw-blur: blur(8px)
}

.grayscale {
  --tw-grayscale: grayscale(100%)
}

.invert {
  --tw-invert: invert(100%)
}

.sepia {
  --tw-sepia: sepia(100%)
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

@media (min-width: 600px) {
  .sm\:h-16 {
    height: 1.6rem
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }
}

@media (min-width: 960px) {
  .md\:text-24 {
    font-size: 2.4rem
  }

  .md\:text-32 {
    font-size: 3.2rem
  }
}

@media (min-width: 1280px) {
}

@media (min-width: 1920px) {
}

@media print {
}
